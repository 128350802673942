<template>
  <div class="container">
    <div class="inputContainer">

      <el-input v-model="code" placeholder="Password for the entry you want to join (case sensitive)"></el-input>
      <div class="searchbtn" @click="search">Search</div>
    </div>

    <div v-if="showMainView" class="item">
      <img
        style="width: 160px; height: 160px"
        v-if="!entry.cover_pic && entry.program==='MR'"
        src="../../assets/pic.png"
      />
      <img
        style="width: 160px; height: 160px"
        v-if="!entry.cover_pic && entry.program==='PIE'"
        src="../../assets/piePic.png"
      />
      <img
        style="width: 160px; height: 160px"
        v-if="!entry.cover_pic && entry.program==='POE'"
        src="../../assets/poePic.png"
      />
      <img
        style="width: 160px; height: 160px"
        v-if="entry.cover_pic"
        :src="entry.cover_pic"
      />
      <div class="main">
        <div class="name">{{ entry.name }}</div>
        <div class="id">ID:{{ entry.entry_code }}</div>
        <div class="tags" v-if="entry.category">
          <span class="tag">{{ entry.category }}</span>
        </div>
        <div class="intro" :title="entry.intro">{{ entry.intro }}</div>
      </div>
    </div>
    <div v-if="showMainView">
      <div class="title">Creator List</div>
      <el-empty
        v-if="!teammates.length"
        description="No Creators Yet"
        :image-size="160"
      ></el-empty>
      <div class="teammate" v-for="(item, index) in teammates" :key="index">
        <div class="name">
          {{
            item.student_info.student_lastName +
            item.student_info.student_givenName
          }}
        </div>
        <div class="school">{{ item.account_info.account_name }}</div>
        <div class="email">{{ item.student_info.student_email }}</div>
      </div>
    </div>

    <div v-if="showMainView" class="joinbtn" @click="apply">
      Join this Entry
    </div>
    <!-- <div v-if="showMainView" class="showTeam" @click="showTeam">
      Creator List
    </div> -->

  </div>
</template>

<script>
import {
  getMREntry,
  getEntryApplications,
  getEntryByCode
} from '../../api/index'
import '../../assets/common/font.css'
export default {
  data () {
    return {
      entry_id: this.$route.query.id,
      entry: { _id: {} },
      teammates: [],
      code: '',
      showTeamDialog: false,
      showMainView: false
    }
  },
  mounted () {
    // if (this.entry_id) {
    //   this.fetchData()
    //   this.getTeammates()
    // }
  },
  methods: {
    fetchData () {
      getMREntry(this.entry_id).then((res) => {
        if (res.data.code === 0) {
          this.entry = res.data.data
          console.log(this.entry)
        }
      })
    },
    getTeammates () {
      getEntryApplications(this.entry_id).then((res) => {
        if (res.data.code === 0) {
          this.teammates = res.data.data
        }
      })
    },
    showTeam () {
      this.showTeamDialog = true
    },
    apply () {
      this.$router.push({
        path: '/confirm',
        query: {
          entry_id: this.entry_id,
          name: this.entry.name,
          code: this.entry.entry_code,
          program: this.$route.query.program
        }
      })
    },
    search () {
      getEntryByCode(this.code).then((res) => {
        if (res.data.code === 0) {
          if (res.data.data) {
            this.entry_id = res.data.data._id.$id
            this.entry = res.data.data
            this.getTeammates()
            this.showMainView = true
          } else {
            this.$message.error('No matching entry was found.')
          }
        } else {
          this.$message.error('No matching entry was found.')
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  font-family: "DDINAlternateRegular";
  .joinbtn {
    background: #0e4890;
    width: 150px;
    height: 34px;
    border-radius: 20px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    position: absolute;
    // top: 10%;
    bottom: 10%;
    right: 50%;
    cursor: pointer;
  }
  .showTeam {
    background: #ffb414;
    width: 150px;
    height: 34px;
    border-radius: 20px;
    line-height: 34px;
    text-align: center;
    color: #fff;
    position: absolute;
    // top: 10%;
    bottom: 10%;
    left: 30%;
    cursor: pointer;
  }
}
.inputContainer {
  width: 68%;
  margin: 50px auto;
  margin-top: 150px;
  display: flex;
  align-items: center;
  font-family: "DDINAlternateRegular";

  ::v-deep .el-input__inner {
    height: 44px;
    border: 2px solid #0e4890;
    border-radius: 8px 0px 0px 8px;
  }
  .searchbtn {
    width: 186px;
    height: 40px;
    line-height: 40px;
    background: #0e4890;
    border-radius: 0px 8px 8px 0px;
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    border: 2px solid #0e4890;
    cursor: pointer;
  }
}
.item {
  display: flex;
  height: 200px;
  width: 90%;
  margin: 8% auto 30px auto;
  font-family: "DDINAlternateRegular";
  img {
    display: block;
    height: 100%;
    border: 1px dotted #0e4890;
    border-radius: 15px;
  }
  .main {
    padding: 4px;
    margin-left: 16px;
    height: 100%;
    .name {
      color: #333333;
      font-weight: bold;
      font-size: 20px;
      text-overflow: ellipsis; //超出文本设置为...
      display: -webkit-box; //将div1转换为盒子模型
      -webkit-line-clamp: 2;
      overflow: hidden; //超出隐藏
      -webkit-box-orient: vertical; //从顶部向底部垂直布置子元素
    }
    .id {
      margin-top: 10px;
      color: #949494;
    }
    .tags {
      margin-top: 10px;
    }
    .tag {
      border: 1.4px solid #17396d;
      color: #17396d;
      padding: 0 8px;
      border-radius: 12px;
      margin-right: 8px;
      font-size: 14px;
    }
    .intro {
      margin-top: 16px;
      color: #949494;
      width: 100%;
      overflow: hidden;
      // height: 90px;
      text-overflow: ellipsis; //超出文本设置为...
      display: -webkit-box; //将div1转换为盒子模型
      -webkit-line-clamp: 2;
      overflow: hidden; //超出隐藏
      -webkit-box-orient: vertical; //从顶部向底部垂直布置子元素
    }
  }
}
.title {
  color: #0e4890;
  font-size: 20px;
  font-weight: bold;
  width: 90%;
  margin: 0 auto;
  margin-top: 15px;
  font-family: "DDINAlternateRegular";
}
.teammate {
  width: 90%;
  margin: 10px auto;
  color: #a4a4a4;
  border-bottom: 1px solid #707070;
  display: flex;
  height: 40px;
  line-height: 40px;
  font-family: "DDINAlternateRegular";
  .name {
    width: 30%;
  }
  .school {
    width: 40%;
  }
  .email {
    width: 30%;
  }
}
</style>
